/* eslint-disable no-undef */
'use_strict';

var isMobile = !($(window).width() >= 768);
var toastr = require('../components/toastr');

/**
 * function used to get the active payment button
 * @returns jQuery{Object}
 */
 function getActivePaymentButton() {
    var activePaymentButton = null;
    if ($('.js-payment-section-buttons').is(':visible')) {
        activePaymentButton = $('.js-payment-section-buttons').find('.desktop-button:not(.d-none)');
    }

    return activePaymentButton;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            toastr(data.valid.message);
        } else {
            toastr(data.resources.emptyCartMsg);

            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * Updates the size selection option of a line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateSizeSelection(data, uuid) {
    var lineItem;
    var messages = '';

    for (var s = 0; s < data.items.length; s++) {
        if (data.items[s].UUID === uuid) {
            lineItem = data.items[s];
            break;
        }
    }
    $('.js-sizes-module-' + uuid).empty();
    if (lineItem.availableSizes && lineItem.availableSizes.length > 0 && lineItem.deliveryOption && lineItem.deliveryOption.toLowerCase() !== 'donated') {
        messages = '';
        messages += '<div class="sizes-module">';
        messages += '<div class="size-list">';
        messages += '<div>';
        for (var b = 0; b < lineItem.availableSizes[0].values.length; b++) {
            var selectedClass = lineItem.availableSizes[0].selectedSize !== null && lineItem.availableSizes[0].selectedSize === lineItem.availableSizes[0].values[b].value ? 'active' : '';
            messages += '<a href="javascript:;" class="' + selectedClass + '" data-value="' + lineItem.availableSizes[0].values[b].value + '">' + lineItem.availableSizes[0].values[b].value + '</a>';
        }
        messages += '</div></div></div>';
        $('.js-sizes-module-' + uuid).html(messages);
    }
}

/**
 * Updates the size selection option of a line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateSizeSelectionForCart(data) {
    var lineItem;
    var messages = '';

    for (var s = 0; s < data.items.length; s++) {
        lineItem = data.items[s];
        var uuid = lineItem.UUID;
        $('.js-sizes-module-' + uuid).empty();
        if (lineItem.availableSizes && lineItem.availableSizes.length > 0 && lineItem.deliveryOption && lineItem.deliveryOption.toLowerCase() !== 'donated') {
            messages = '';
            messages += '<div class="sizes-module">';
            messages += '<div class="size-list">';
            messages += '<div>';
            for (var b = 0; b < lineItem.availableSizes[0].values.length; b++) {
                var selectedClass = lineItem.availableSizes[0].selectedSize !== null && lineItem.availableSizes[0].selectedSize === lineItem.availableSizes[0].values[b].value ? 'active' : '';
                messages += '<a href="javascript:;" class="' + selectedClass + '" data-value="' + lineItem.availableSizes[0].values[b].value + '">' + lineItem.availableSizes[0].values[b].value + '</a>';
            }
            messages += '</div></div></div>';
            $('.js-sizes-module-' + uuid).html(messages);
        }
    }
}

/**
 * function used to handle coupon code application on the frontend
 * @param {Object} data - AJAX response from the server
 */
function handleCouponCode(data) {
    // update UI with coupon code applied UI
    var couponData = [];
    for (var i = 0; i < data.totals.discounts.length; i++) {
        if (data.totals.discounts[i].type === 'coupon') {
            couponData.push({
                couponCode: data.totals.discounts[i].couponCode,
                UUID: data.totals.discounts[i].UUID
            });
        }
    }

    if (couponData.length > 0) {
        // coupon applied
        $('.js-cart-coupon-applied-area').empty();
        var cloneTemplate = $('.js-sample-ui-applied-template').clone();
        $(cloneTemplate).find('.js-applied-code').text(couponData[0].couponCode);
        $(cloneTemplate).find('.remove-coupon').attr('data-code', couponData[0].couponCode);
        $(cloneTemplate).find('.remove-coupon').attr('data-uuid', couponData[0].UUID);
        $(cloneTemplate).removeClass('d-none');
        cloneTemplate.appendTo('.js-cart-coupon-applied-area');
    } else {
        // coupon is not applied
        $('#promo-box-ui').removeClass('d-none');
        $('.js-cart-coupon-applied-area').empty();
    }
}

/**
 * function used to update the cart qty after aja call
 * @param {Object} data - AJAX response from the server
 */
function handleCartTicketQty(data) {
    if (!data) {
        return;
    }
    var items = data.items;
    if (items.length) {
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            var classToSearch = '.uuid-' + item.UUID;
            var $elm = $(classToSearch);
            if ($elm.length) {
                $elm.find('.campaign-ticket-count .numberoftickets').html(item.ticketCount);
            }
        }
    }
}


/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    if (!data) {
        return;
    }
    handleCouponCode(data);
    handleCartTicketQty(data);
    $('.js-render-cart-html').html(data.cartTotalHtml);

    // logic to check if bundle needs to be rendered again
    $('.js-bundle-promo-render').html(data.bundleSuggestion);
    $('.js-suggestion-render').html(data.cartSuggestion);
    // upate the get credit value
    $('.js-get-credit-value').html(data.totalCreditRecieved.formattedValue);
}

/**
 * updates the shipping status on cart page
 * @param {Object} data - AJAX response from the server
 */
function updateShippingSippingStatus(data) {
    if (!data) {
        return;
    }

    var items = data.items;
    var isShippingApplicale = false;
    var isShippingProduct = false;
    if (items.length > 0) {
        if (isMobile) {
            for (var i = 0; i < items.length; i++) {
                if (!items[i].isCreditProduct) {
                    var $productDomElm = $('.cart-section-item[data-product-id=' + items[i].id + ']');
                    if ($productDomElm.length > 0 && !$productDomElm.hasClass('iscredit')) {
                        $productDomElm.attr('data-donatebale', items[i].donatable);
                        if (!items[i].donatable) {
                            isShippingApplicale = true;
                        }
                        if (items[i].campaignAttributes.isShippingApplicable) {
                            isShippingProduct = true;
                        }
                    }
                }
            }

            if (isShippingApplicale) {
                $('#js-shipping-area-module').removeClass('d-none');
            } else {
                $('#js-shipping-area-module').addClass('d-none');
            }
        } else {
            var $parentcartContainer = $('.cartNewContainer');
            for (var c = 0; c < items.length; c++) {
                var $productDomElmDsk = $parentcartContainer.find('.item-block.product-info.product-detail[data-pid=' + items[c].id + ']');
                if ($productDomElmDsk.length > 0 && !$productDomElmDsk.hasClass('iscredit')) {
                    $productDomElmDsk.attr('data-donatebale', items[c].donatable);
                    if (!items[c].donatable) {
                        isShippingApplicale = true;
                    }

                    if (items[c].campaignAttributes.isShippingApplicable) {
                        isShippingProduct = true;
                    }
                }
            }

            var $shippingContainer = $('.shipping-page-details');
            if (isShippingApplicale) {
                $($shippingContainer).removeClass('d-none');
                $('.make-payment-button').addClass('shipping-allowed');
            } else {
                $($shippingContainer).addClass('d-none');
                $('.make-payment-button').removeClass('shipping-allowed');
                $($shippingContainer).find('.delivery-box').each(function () {
                    $(this).removeClass('checked');
                });
            }

            if (isShippingProduct) {
                $($shippingContainer).find('.standard-shipping-box').removeClass('d-none');
            } else {
                $($shippingContainer).find('.standard-shipping-box').addClass('d-none');
            }
        }
    }

    // logic to handle the standard shipping option
    if (data.showDeliveryOption) {
        $('.standard-shipping-box').removeClass('d-none');
    } else {
        $('.standard-shipping-box').addClass('d-none');
    }

    $('body').attr('data', JSON.stringify(data));
}

/**
 * re-renders the approaching discount messages
 * @param {Object} data - updated cart model
 */
function updateApproachingDiscounts(data) {
    var approachingDiscounts = data.approachingDiscounts;
    var html = '';
    $('.approaching-discounts').empty();
    $('.approaching-discounts').addClass('d-none');
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            if (isMobile) {
                if (item.discountType === 'PERCENTAGE') {
                    html += '<span>' + item.discountMsgWeb + '</span>';
                } else {
                    html += '<span>' + item.discountMsg + '</span>';
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (item.discountType === 'PERCENTAGE') {
                    html += '<span class="discount_percent">' + item.percentageDiscount + '</span>';
                    html += '<div class="discountCopy">';
                    html += '<h6 class="title">' + resources.labelDiscount;
                    html += '</h6>';
                    html += '<p class="description">' + item.discountMsgWeb + '</p>';
                    html += '</div>';
                } else {
                    html += '<div class="discountCopy"><div class="single-approaching-discount text-center">'
                        + item.discountMsg + '</div></div>';
                }
            }
        });
    }

    if (html !== '') {
        $('.approaching-discounts').append(html);
        $('.approaching-discounts').removeClass('d-none');
    }

    if (isMobile) {
        if (data.isApproachingApplied) {
            $('.approaching-discounts').addClass('d-none');
            $(document).trigger('approaching:applied');
            var discounts = data.totals.discounts;
            var approachingDiscountsPer = '';
            for (var i = 0; i < discounts.length; i++) {
                if (discounts[i].lineItemText === 'Approaching Discount') {
                    approachingDiscountsPer = discounts[i].callOutMsg;
                }
            }
            if (approachingDiscountsPer !== '') {
                $('.successfullyApplied.d-none').removeClass('d-none');
                $('.successfullyApplied').find('.percentage').html(approachingDiscountsPer);
            }
        }
    }
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">'
                + lineItem.availability.inStockDate
                + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            var newColor = 'Color: ' + colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            var newSize = 'Size: ' + sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images.prize[0].url);
        $(imageSelector).attr('alt', lineItem.images.prize[0].alt);
        $(imageSelector).attr('title', lineItem.images.prize[0].title);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Shows/Hides Free ticket icons
 * @param {boolean} donated if donated
 */
function handleFreeTicketIcons(donated) {
    var mobileFreeTickets = $('.campaign-ticket-count:not(".non-donateable")');
    var desktopFreeTickets = $('.item-block:not(".donation-with-points") .ticket-number:not(".non-donateable")');
    var desktopFreeTicketsPrint = $('.item-block:not(".donation-with-points") .per-unit');
    var desktopFreeTicketsPrintIpoints = $('.item-block.donation-with-points .per-unit');
    if (mobileFreeTickets.length) {
        if (donated) {
            mobileFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    $this.find('.number_tickets .numberoftickets').html(ticketCount);
                } else {
                    $this.find('.number_tickets .numberoftickets').html((ticketCount + 1));
                }
            });
        } else {
            mobileFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    $this.find('.number_tickets .numberoftickets').html((ticketCount - 1));
                }
            });
        }
    } else {
        // eslint-disable-next-line no-lonely-if
        if (donated) {
            desktopFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    if (ticketCount === 2) {
                        // $this.closest('.earlybird-interaction .js-free-tickets-icon').removeClass('d-none');
                        $this.html(ticketCount + ' ' + resources.tickets);
                        desktopFreeTicketsPrint.html(resources.perUnitDonated);
                        desktopFreeTicketsPrintIpoints.html(resources.perUnitDonated);
                    } else {
                        $this.html(ticketCount + ' ' + resources.tickets);
                        desktopFreeTicketsPrint.html(resources.perUnitDonated);
                        desktopFreeTicketsPrintIpoints.html(resources.perUnitDonated);
                    }
                }
            });
            // freeTicketIcons.removeClass('d-none');
        } else {
            desktopFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    if (ticketCount === 2) {
                        // $this.closest('.earlybird-interaction .js-free-tickets-icon').addClass('d-none');
                        $this.html((ticketCount - 1) + ' ' + resources.ticket);
                        desktopFreeTicketsPrint.html(resources.perUnitDouble);
                        desktopFreeTicketsPrintIpoints.html(resources.perUnitIpoints);
                    } else {
                        $this.html((ticketCount - 1) + ' ' + resources.tickets);
                        desktopFreeTicketsPrint.html(resources.perUnitDouble);
                        desktopFreeTicketsPrintIpoints.html(resources.perUnitIpoints);
                    }
                }
            });
        }
    }
}

/**
 * function used to validate the shipping validation
 */
function validateShippingValidation() {
    if (isMobile) {
        var $mobileShippingElm = $('#js-shipping-area-module');
        // if shipping module is visible 
        if ($mobileShippingElm .is(':visible')) {
            // check if any option is selcted for shipping
            var $shipmentSelected = $('#js-shipping-area-module').find('.js-delivery-box.checked');
            if ($shipmentSelected.length > 0) {
                // any option is selected, now check if we have selected delivery then address should be there
                if ($shipmentSelected.hasClass('standard-shipping-box')) {
                    if ($shipmentSelected.find('.address-btn.saved.active').length > 0) {
                        // address is selected do nothing
                    } else {
                        $('.newbuttonMinOrder .mobile-button').addClass('is-disabled');
                    }
                }
            } else {
                $('.newbuttonMinOrder .mobile-button').addClass('is-disabled');
            }
        }
    }
}

/**
 * Updates product and ticket quantities
 * @param {jQuery} $donateContext donation section
 * @param {Object} basket basket model returned from ajax
 */
function handleTicketQuantity($donateContext, basket) {
    var ticketQuantity = $('.js-ticket-quantity', $donateContext);
    ticketQuantity.html(basket.ticketQuantity);
}

/**
 * replace content of modal
 * @param {JSON} data JSON
 */
function minimumOrder(data) {
    if (data.checkoutAllowed && !data.restrictForSizes) {
        $('.newbuttonMinOrder .mobile-button').removeClass('is-disabled');
        $('.newbuttonMinOrder .minimumOrder-indicator').removeClass('show');
        $('.item-block .item-quantity .js-plus').removeClass('pulseAnim');
    } else {
        $('.newbuttonMinOrder .mobile-button').addClass('is-disabled');
        $('.newbuttonMinOrder .minimumOrder-indicator').addClass('show').find('p b').html(data.amountRequiredForCheckout);
        $('.minimumOrder-indicator .indiBar span').css('width', `${parseInt(data.checkoutPercentageRequired)}%`);
        $('.item-block .item-quantity .js-plus').addClass('pulseAnim');
    }

    // check for shiping option selection if visible then check for that as well
    if (!data.isDonated) {
        validateShippingValidation();
    }

}

// eslint-disable-next-line valid-jsdoc
/**
 * UpdateTotals ifremoved from quick view modal within cart page
 */
 function updatePostTotal(basket) {
    updateCartTotals(basket);
    updateShippingSippingStatus(basket);
}

/**
 * function to handle the full credit scenario
 * @param {Object} data - AJAX response from the server
 */
function handleFullCreditPayment(data) {
    $('.js-render-cart-html').html(data.cartTotalHtml);
    if (isMobile) {
        if (!$('#cart-area-page').is('visible')) {
            $('.js-cart-page-buttons').addClass('d-none');
            $('.js-payment-section-buttons').removeClass('d-none');
        }
        var classToActivated = '.credit-content';
        $('.payment-card-block .tab-pane').removeClass('active');
        $(classToActivated).addClass('active');
        $('.js-select-payment-box-mobile').removeClass('selected');
        $('.js-select-payment-box-mobile-by-card').removeClass('selected');
        $('.card-payment-area').removeClass('show');
        $('.payment-subtitle, .payment-card-block').hide();
        $('.js-payment-section-buttons .desktop-button').addClass('d-none');
    } else {
        var classToActivated = '.credit-content';
        $('.payment-card-block .tab-pane').removeClass('active').addClass('d-none');
        $('.payment-form .tab-pane').removeClass('active');
        $(classToActivated).addClass('active').removeClass('d-none');
        $('.js-select-payment-box-desktop').removeClass('selected');
        $('.js-select-payment-box-desktop-by-card').removeClass('selected');
        $('.js-payment-section-buttons .desktop-button').addClass('d-none');
        $('.payment-page-detail').addClass('d-none');
        $('.js-payment-section-buttons .credits-pay-buton').removeClass('d-none');
        $('.js-payment-section-buttons').removeClass('d-none');
        $(document).trigger('desktop:walletPaymentValidation');
    }
}


/**
 * function to handle the partial credit scenario
 * @param {Object} data - AJAX response from the server
 */
function handlePartialCreditPayment(data) {
    $('.js-render-cart-html').html(data.cartTotalHtml);
    if (isMobile) {
        if (!$('#cart-area-page').is('visible')) {
            $('.js-cart-page-buttons').addClass('d-none');
            $('.js-payment-section-buttons').removeClass('d-none');
        }
        $('.payment-subtitle, .payment-card-block').show();
        var selectedPaymentMethodID = $('.select-payment-method-box.selected').data('id');
        if (selectedPaymentMethodID === 'credit_card') {
            $(document).find('.js-add-new-card').trigger('click');
        } else if (typeof selectedPaymentMethodID !== 'undefined') {
            $(document).find('.js-select-payment-box-mobile.selected').trigger('click');
        }
        $('.tab-pane.credit-content').removeClass('active').addClass('d-none');
    } else {
        $('.payment-page-detail').removeClass('d-none');
        $('.tab-pane.credit-content').removeClass('active').addClass('d-none');
        $(document).find('.js-add-new-card').trigger('click');
        // check which validation to trigger
        // var $selectedPaymentMethod = $('.payment-page-detail .select-payment-method-box.selected');
        // if ($selectedPaymentMethod.length > 0) {
        //     if ($selectedPaymentMethod.attr('id') === 'credit_card') {
        //         if ($('.saved-payment-instrument').length > 0) {
        //             $(document).trigger('desktop:saveCardValidation');
        //         } else {
        //             $(document).trigger('desktop:newCardValidation');
        //         }
        //     } else {
        //         $(document).trigger('desktop:walletPaymentValidation');
        //     }
        // } else {
        //     var activeButtton = getActivePaymentButton();
        //     if ($(activeButtton).length > 0) {
        //         $(activeButtton).addClass('is-disabled');
        //         $(activeButtton).attr('disabled', 'disabled');
        //     }
        // }
    }
}

/**
 * function to handle full desktop validation
 * @param {Object} data - AJAX response from the server
 */
function handleDesktopAllValidations(data) {
    if (data.isCreditApplied && data.isFullyPaidViaCredit) {
        handleFullCreditPayment(data);
    } else {
        handlePartialCreditPayment(data)
    }
    // var activeButtton = getActivePaymentButton();
    // if (data.restrictForSizes) {
    //     if ($(activeButtton).length > 0) {
    //         $(activeButtton).addClass('is-disabled');
    //         $(activeButtton).prop('disabled', 'disabled');
    //     }

    //     $('.payment-page-detail').addClass('restrict-for-size');
    //     $('.shipping-page-details').addClass('restrict-for-size');
    // } else {
    //     $('.payment-page-detail').removeClass('restrict-for-size');
    //     $('.shipping-page-details').removeClass('restrict-for-size');
    //     if ($(activeButtton).length > 0 && $(activeButtton).hasClass('credit_card')) {
    //         $('.saved-payment-instrument.selected-payment #saved-payment-security-code').trigger('keyup');
    //         $('.new-card-area.show #securityCode').trigger('keyup');
    //     } 
    // }

    // var minumumAmount = parseFloat(data.amountRequiredForCheckout.replace(/[^0-9.]+/g, ''));
    // if (data.checkoutAllowed && minumumAmount === 0) {
    //     $('.minimumOrder-indicator').removeClass('show');
    // } else if (!data.checkoutAllowed && minumumAmount > 0) {
    //     $('.minimumOrder-indicator').addClass('show');
    //     $('.newbuttonMinOrder .minimumOrder-indicator').addClass('show').find('p b').html(data.amountRequiredForCheckout);
    //     $('.minimumOrder-indicator .indiBar span').css('width', `${parseInt(data.checkoutPercentageRequired)}%`);
    // }
}

/**
 * used to update the shipping address label
 */
 function updateAddressText() {
    $('.standard-shipping-box .newAddress a').not('.js-add-new-address').each(function () {
        if (!$(this).hasClass('active')) {
            $(this).text($(this).attr('data-addressalias'));
        }
    });
}

/**
 * used to update shipping address on view
 * @param {*} data response
 */
function updateAddressOnStorefront(data) {
    if ($('.standard-shipping-box ').length > 0) {
        var applicableAddress = [];
        var customerSavedAddresses = data.customer.addresses;
        if (customerSavedAddresses.length > 0 && 'addressId' in data) {
            applicableAddress = customerSavedAddresses.filter(function (el) {
                return el.ID === data.addressId;
            });
        }
        var addressString = data.suite + ', ' + data.address2 + ', ' + data.address1 + ', ' + data.city + ',United Arab Emirates';
        if (applicableAddress.length > 0) {
            addressString = applicableAddress[0].suite + ', ' + applicableAddress[0].address2 + ', ' + applicableAddress[0].address1 + ', ' + applicableAddress[0].city + ', ' + applicableAddress[0].countryCode.displayValue;
        }

        // $('.standard-shipping-box').find('.changeOfAddress').text(addressString).removeClass('d-none');
        $('.newAddress a[data-addressid="' + data.addressId + '"]').remove();
        var addressElm = '<a href="javascript:;" class="address-btn active saved" data-uuid="' + data.addressUUID + '" data-addressid="' + data.addressId + '" data-address="' + addressString + '"><h5>' + data.deliverString + '</h5><p>' + addressString + '</p></a>';
        var addressElmDesktop = '<a href="javascript:;" class="address-btn active" data-uuid="' + data.addressUUID + '" data-addressid="' + data.addressId + '" data-address="' + addressString + '">' + resources.deliverLabel + ' ' +data.deliverString + '</a>';
        $('.standard-shipping-box .newAddress').children().removeClass('active');
        if (isMobile) {
            $('.standard-shipping-box .newAddress').prepend(addressElm);
        } else {
            var $children = $('.standard-shipping-box .newAddress .address-btn:not(.js-add-new-address)');
            $children.each(function() {
                var $this = $(this);
                var $id = $this.data('addressid');
                $this.text($id);
            })
            // $(addressElmDesktop).insertBefore('.standard-shipping-box .newAddress .js-add-new-address');
            $('.standard-shipping-box .newAddress').prepend(addressElmDesktop);
            $('.standard-shipping-box').find('.changeOfAddress').text(addressString).removeClass('d-none');
            updateAddressText();

            if (data.customer.addresses.length >= 3) {
                // $('.newAddress .js-add-new-address').remove();
                var img = $('.newAddress .js-add-new-address').find('img').detach();
                $('.newAddress .js-add-new-address').empty().append(img);
            }
        }
        
        
        $('#submitPaymentCartBtn').removeClass('is-disabled');
        $('#submitPaymentCartBtn').removeAttr('disabled');
    }

    if ($('.addressTabs').length > 0) {
        var formattedAddress = data.formattedAddress;
        if (formattedAddress) {
            $('.addressTabs').find('li.active').attr('data-address', JSON.stringify(formattedAddress));
            $('.addressTabContent.active form').attr('data-update', true);
        }
    }
}

/**
 * used to update line item subtotal
 * @param {*} data response
 */
function updateSubTotal(data, uuid, $lineItem) {
    var $renderedPrice = '';
    data.items.map(function (item) {
        if (item.UUID === uuid) {
            $renderedPrice = item.priceTotal.renderedPrice
        }
    });

    if ($renderedPrice !== '') {
        $lineItem.closest('.item-block').find('.big-price').replaceWith($renderedPrice);
    }
}

module.exports = {
    updateSizeSelection: updateSizeSelection,
    handleCouponCode: handleCouponCode,
    updateCartTotals: updateCartTotals,
    updateShippingSippingStatus: updateShippingSippingStatus,
    validateBasket: validateBasket,
    updateApproachingDiscounts: updateApproachingDiscounts,
    updateAvailability: updateAvailability,
    updateProductDetails: updateProductDetails,
    handleFreeTicketIcons: handleFreeTicketIcons,
    handleTicketQuantity: handleTicketQuantity,
    updateSizeSelectionForCart: updateSizeSelectionForCart,
    minimumOrder: minimumOrder,
    updatePostTotal: updatePostTotal,
    handleFullCreditPayment: handleFullCreditPayment,
    handlePartialCreditPayment: handlePartialCreditPayment,
    handleDesktopAllValidations: handleDesktopAllValidations,
    updateAddressOnStorefront: updateAddressOnStorefront,
    getActivePaymentButton: getActivePaymentButton,
    updateSubTotal: updateSubTotal
};
