'use strict';

var base = require('base/product/base');
var focusHelper = require('base/components/focus');
var isMobile = !($(window).width() >= 768);
var toastr = require('../components/toastr');
var lottie = require('lottie-web');
const braze = require('../braze');
const helper = require('./helper');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * function used to check if the size is selected or not if visible
 */
function checkIfSizeIsSelected() {
    var sizeSelected = true;
    if (!isMobile) {
       $('.size-selection-wrapper-js:visible .sizes-module').each(function( index ) {
            if ($(this).find('.size-list a.active').length === 0) {
                sizeSelected = false;
            }
        });
    }

    return sizeSelected;
}


/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    toastr(message);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade show" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Toggles the delete button for a lineItem
 * @param {string} e - to get the target class
 */
function showOptions(e) {
    var $this = $(e.currentTarget);
    $('.js-cart-options').toggleClass('hide');
    if ($this.hasClass('hide-button')) {
        $('.earlybird-interaction').show();
        $('.cart-interaction-button').hide();
    } else {
        $('.earlybird-interaction').hide();
        $('.cart-interaction-button').css({ display: 'flex' });
    }
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 * @param {string} tagEvent - google analytics tag event name
 * @param {string} tagParam - google analytics tag params
 */
function confirmDelete(actionUrl, productID, productName, uuid, tagEvent, tagParam) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $deleteConfirmBtn.data('gtag-event', tagEvent);
    $deleteConfirmBtn.data('gtag-param', tagParam);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 * @param {string} tagEvent - google analytics tag event name
 * @param {string} tagParam - google analytics tag params
 */
function openSavedCard() {
    $(this).parent().siblings('.card-item').find('.mobile-CVV').removeClass('active');
    $(this).parent().siblings('.card-item').hide();
    $(this).next().toggleClass('active');
    $(this).find('.use-this').text('').toggleClass('closed');
    $('.card-item').find('.use-this').removeClass('blue');
    if ($(this).next().hasClass('active')) {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        $('.payment-ipoints, .payment-card h4, .payment-card h5, .payment-card .add-new-card').hide();
        // $('.user-payment-instruments').removeClass('checkout-hidden');
        $('.mobilePaymentError').removeClass('errorFound');
        $('.mobilePaymentError span').text('');
        $(this).next().find('.dummy').first().focus();
        $('.payment-information').data('is-new-payment', false);
        $('.tooltip-for-ipoints').removeClass('checked');
        $('body').trigger('GTM:opensavedcard');
    } else {
        $('.payment-ipoints, .payment-card h5, .payment-card h4,.payment-card .add-new-card').show();
        // $('#submitPaymentCartBtn').prop('disabled', true);
        // $('#submitPaymentCartBtn').addClass('is-disabled');
        $('.dummy').val('');
        $('.card-item').show();
        // eslint-disable-next-line no-undef
        $(this).find('.use-this').text(resources.paymentUseThis);
    }
}

/**
 * Go to forgot password
 * @constructor
 */
function mobileOtpFill() {
    $('.dummy').keyup(function (event) {
        var len = $('.mobile-CVV.active .dummy').val().toString().length;
        var act = $('.mobile-CVV.active .dummy').parent().find('.cvc-number');
        var accumulatedValue = '';

        if (len === 1) {
            $(this).next().focus();
        }
        if ((event.which === 8 || event.which === 46)) {
            $(this).prev('input').focus();
        }
        // eslint-disable-next-line consistent-return
        $('.mobile-CVV.active .dummy').each(function () {
            var $trim = $(this).val();
            $trim = $.trim($trim);
            accumulatedValue += $trim;
            if ($trim.length !== 0) {
                $('#submitPaymentCartBtn').prop('disabled', false);
                $('#submitPaymentCartBtn').removeClass('is-disabled');
                act.val(accumulatedValue);
            } else {
                $('#submitPaymentCartBtn').prop('disabled', true);
                $('#submitPaymentCartBtn').addClass('is-disabled');
                return false;
            }
        });
    });
}

/**
 * Go to forgot password
 * @constructor
 */
function openPaymentOverlayer() {
    $('.cartOverlay').addClass('showModule');
    $('.selected-payment .cvc-number').focus();
    $('body').trigger('GTM:newcard');
}
/**
 * Go to forgot password
 * @constructor
 */
function closePaymentOverlayer() {
    $('.cartOverlay').removeClass('showModule');
    $('body').trigger('GTM:newcard');
}

/**
 * Go to forgot password
 * @constructor
 */
function openNewcard() {
    $('.payment-information').data('is-new-payment', true);
    $('.js-select-payment-box-desktop').removeClass('selected');
    $('.js-add-new-card').addClass('selected');
    $('.new-card-area').addClass('show');
    $('.credit-card-form').removeClass('checkout-hidden');
    $('.mobile-button.newCard-button').addClass('show');
    // $('.mobile-button.paypal, .mobile-button.applePay').removeClass('show');
    // $('.mobile-button.careempay').addClass('hide');
    $('.mobile-button.addCvv').addClass('hide');
    $('.tab-pane').removeClass('active');
    $('.tab-pane.credit_card-content').addClass('active').removeClass('d-none');
    // $('.mobile-button.addCvv.new-card-button').removeClass('hide').addClass('show').addClass('is-disabled');
    // $('.mobile-button.addCvv.existing-card-button').removeClass('show').addClass('hide');
    // $('.saved-payment-instrument').removeClass('selected-payment').find('#saved-payment-security-code').addClass('d-none');
    // $('.js-payment-section-buttons .desktop-button.credit_card.new-card-button').removeClass('d-none').addClass('is-disabled');
    $('.js-payment-section-buttons').removeClass('d-none');
    // $('.js-payment-section-buttons .desktop-button.applePay').addClass('d-none');
    // $('.desktop-button.careempay').addClass('d-none');
    // $('.js-payment-section-buttons .desktop-button.paypal').addClass('d-none');
    $('.js-payment-section-buttons .desktop-button.credits-pay-buton').addClass('d-none');
    $('.js-payment-section-buttons .desktop-button.submit-payment').removeClass('d-none');
    // $('.desktop-button.existing-card-button').addClass('d-none');
    // $('#securityCode').val('');
    $('body').trigger('GTM:newcard');
    $('.js-cart-page-buttons').addClass('d-none');
    $('.js-select-payment-box-mobile').removeClass('selected');
}

/**
 * Close New Card
 * @constructor
 */
function closeNewCard() {
    // $('.new-card-area').removeClass('showModule').addClass('hideModule');
    var $id = $('.select-payment-method-box.selected').data('id');
    $('.payment-information').data('is-new-payment', false);
    $('.new-card-area input[type=tel]').val('');
    $('.mobile-button.addCvv.new-card-button').addClass('hide').removeClass('show').addClass('is-disabled');
    $('.new-card-area .newCreditCard-actions .mobile-button').addClass('is-disabled');
    $('.new-card-area').removeClass('show');
    $('.stored-payments').fadeIn();
    $('.credit-card-form').addClass('checkout-hidden');
    $('.overlayerCartAction').addClass('show');
    
    $('body').trigger('GTM:newcard');


    // if ($id === 'paypal') {
    //     $('.bottom-floater .mobile-button.addCvv').addClass('hide');
    //     $('.bottom-floater .mobile-button.applePay').removeClass('show');
    //     $('.bottom-floater .mobile-button.paypal').addClass('show');
    //     $('.mobile-button.newCard-button').removeClass('show');
    // } else if ($id === 'applepay') {
    //     $('.bottom-floater .mobile-button.addCvv').addClass('hide');
    //     $('.bottom-floater .mobile-button.applePay').addClass('show');
    //     $('.bottom-floater .mobile-button.paypal').removeClass('show');
    //     $('.mobile-button.newCard-button').removeClass('show');
    // } else if ($id === 'credit_card') {
    if ($id === 'credit_card') {
        $('.bottom-floater .mobile-button.addCvv').addClass('hide');
        $('.bottom-floater .mobile-button.applePay').addClass('hide');
        $('.bottom-floater .mobile-button.paypal').addClass('hide');
        $('.mobile-button.existing-card-button').addClass('show').removeClass('hide');
    }  else {
        $('.bottom-floater .mobile-button.addCvv').removeClass('hide');
        $('.bottom-floater .mobile-button.applePay').removeClass('show');
        $('.bottom-floater .mobile-button.paypal').removeClass('show');
        $('.mobile-button.newCard-button').removeClass('show');
    }
}

/**
 * function to go to payment page
 */
function goToPaymentPage() {
    $('.shipping-page-details').fadeOut(100, function () {
        // $('.content-wrapper header h1').text($('#registeration-form').length > 0 ? resources.paymentPageHeading2 : resources.paymentPageHeading);
        $('header.withProgress').hide();
        $('.mobileHideInPayment').hide();
        $('.cart-shipping').fadeOut();
        $('.payment-page-detail').fadeIn();
    });
}

/**
 * used to save guest user address to basket
 */
function saveAddressToBasket() {
    var $activeForm = $('.onlyForNonRegistered .addressTabContent.active .js-add-address-form');
    if ($activeForm) {
        var url = $activeForm.attr('action');
        $.ajax({
            url: url,
            type: 'POST',
            data: $activeForm.serialize(),
            success: function (data) {
                if (!data.success) {
                    toastr(data, data.error);
                } else {
                    goToPaymentPage();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                }
            }
        });
    }
}

function isWeeklyWinnerDom() {
    var $weeklyPopupDom = $('.weekly_winner_popup');
    if ($weeklyPopupDom.length > 0) {
        $('.weekly_winner_popup').removeClass('hide');
    } else {
        return
    }
}

module.exports = function () {
    $('body').on('cart:quickViewMinus', function (data) {
        helper.updatePostTotal(data.basket);
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();
        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        var tagEvent = $(this).data('gtag-event');
        var tagParam = $(this).data('gtag-param');
        confirmDelete(actionUrl, productID, productName, uuid, tagEvent, tagParam);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.optional-promo', function (e) {
        e.preventDefault();
        $('.js-promo-box:not(.shippingPage)').toggle();
        $(this).addClass('unvisible');
    });

    $('body').on('click', '.jsRemoveCartItem', function (e) {
        e.preventDefault();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };


        var page = 'home';
        if ($('.page').attr('data-action') === 'Home-Show') {
            page = 'Home';
        } else if ($('.page').attr('data-action') === 'Product-Show') {
            page = 'L2';
        } else if ($('.page').attr('data-action') === 'Cart-Show') {
            page = 'Cart';
        } else if ($('.page').attr('data-action') === 'Wishlist-Show') {
            page = 'Wishlist';
        }

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        // $.spinner().start();
        if (isMobile) {
            $('.js-modal-confirm-delete').addClass('visible');
            $('.keepit').unbind('click');
            $('.yesremove').unbind('click');
            $('.keepit').click(function () {
                $('.js-modal-confirm-delete').removeClass('visible');
            });
            $('.yesremove').click(function () {
                $('.js-modal-confirm-delete').removeClass('visible');
                $.spinner().start();
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        $('body').trigger('cart:update', data.basket);
                        $('body').trigger('GTM:removeCart', data);
                        $(document).trigger('cart:removeItem', {
                            pid: productID
                        });

                        window.location.reload();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    }
                });
            });
        } else {
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $(document).trigger('cart:removeItem', {
                        pid: productID
                    });
                    $('body').trigger('GTM:removeCart', data);
                    $('body').trigger('cart:update', data.basket);
                    window.location.reload();
                },
                error: function (err) {
                    $.spinner().stop();
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                    }
                }
            });
        }
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var currentScope = $(this);
        var $sizeSelectionModule = $('.js-sizes-module-' + uuid);
        var selectedSizes = [];
        var $productDom = $('.uuid-' + uuid);
        var selectedQty = parseInt($productDom.find('.quantity-form input').val());
        $sizeSelectionModule.find('.sizes-module .size-list a.active').each(function () {
            for (var d = 0; d < selectedQty; d++) {
                selectedSizes.push($(this).data('value'));
            }
        });
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid,
            page: 'cart',
            size: JSON.stringify(selectedSizes)
        };
        url = appendToUrl(url, urlParams);

        // $(this).parents('.card').spinner().start();
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                if (data.error) {
                    createErrorNotification(data.message);
                    $(currentScope).val(preSelectQty);
                    $.spinner().stop();
                    return;
                }
                // $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                helper.updateCartTotals(data);
                helper.updateShippingSippingStatus(data);
                helper.updateApproachingDiscounts(data);
                helper.updateAvailability(data, uuid);
                helper.updateSubTotal(data, uuid, $(this));
                // helper.updateSizeSelection(data, uuid);
                helper.validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                // update gtm data
                var gtagElems = $('span[data-gtag-event="begin_checkout"]');
                if (data && 'gtags' in data) {
                    gtagElems.attr('data-gtag-param', data.gtags[0].param);
                    $('body').trigger('GTM:cartUpdate', data);
                }

                if (Object.keys(data.removeFromCartGtag).length) {
                    // trigger add to cart event for braze
                    braze.handleAddRemoveEvent(data.removeFromCartGtag);
                } else {
                    // trigger add to cart event for braze
                    braze.handleAddRemoveEvent(data.addToCartGtag);
                }

                // window.dispatchEvent( new Event('careempayready') );

                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                } else {
                    $.spinner().stop();
                }

                if (!isMobile) {
                    helper.handleDesktopAllValidations(data);
                } else {
                    helper.minimumOrder(data);
                }

                $('body').trigger('GTM:globalCartAction', data);

            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        // $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    helper.updateCartTotals(data);
                    helper.updateShippingSippingStatus(data);
                    helper.updateApproachingDiscounts(data);
                    helper.validateBasket(data);
                }
                // $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    // $.spinner().stop();
                }
            }
        });
    });

    $('body').on('submit', '.promo-code-form', function (e) {
        e.preventDefault();
        $.spinner().start();
        var $this = $(this);
        var $form = $('.promo-code-form');

        if (!isMobile) {
            $('.coupon-missing-error').hide();
            $('.coupon-error-message').empty();
            if (!$this.find('.coupon-code-field').val()) {
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
                $('.coupon-missing-error').show();
                $.spinner().stop();
                return false;
            }
            
            $('.promo-code-form .form-control').removeClass('is-invalid');
            $('.coupon-error-message').empty();

            $.ajax({
                url: $form.attr('action'),
                type: 'GET',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('.js-promo-box:not(.shippingPage)').hide();
                        $('.js-promo-code-hide').hide();
                        $('.js-wrong-promo-code').css({ display: 'flex' });
                        setTimeout(function () {
                            $('.js-wrong-promo-code').hide();
                            $('.js-promo-box:not(.shippingPage)').show();
                            $('.js-promo-code-hide').show();
                        }, 3000);
                    } else {
                        $('.js-promo-box:not(.shippingPage)').hide();
                        $('.js-promo-code-hide').hide();
                        $('.js-promo-box:not(.shippingPage)').removeClass('onScrollShow');

                        $('.js-promo-name').html(data.totals.discounts[0].couponCode);
                        $('.js-promo-box:not(.shippingPage)').removeClass('show');
                        $('.add_promo_code').addClass('hide');
                        helper.updateCartTotals(data);
                        helper.updateShippingSippingStatus(data);
                        helper.updateApproachingDiscounts(data);
                        helper.validateBasket(data);
                        braze.sendCouponApplyEvent(data.totals.discounts[0].couponCode, data.totals.value);
                        if (!isMobile) {
                            if ($('.user-payment-instruments').hasClass('checkout-hidden')) {
                                $(document).trigger('desktop:newCardValidation');
                            } else {
                                $(document).trigger('desktop:saveCardValidation');
                            }
                        }

                        $('.js-correct-promo-code').css({ display: 'flex' });

                        setTimeout(function () {
                            $('.js-correct-promo-code').hide();
                            $('.js-promo-code-hide').show();
                            $('.new-grandTotal-box .right-payment .grand-total-amount').addClass('green');
                        }, 3000);


                        setTimeout(function () {
                            $('.new-grandTotal-box .right-payment .grand-total-amount').removeClass('green');
                            $('.new-grandTotal-box .left-payment').append(data.totals.discountsHtml);
                        }, 6000);
                    }
                    if (isMobile) {
                        $('.js-closepromopop').removeClass('d-none');
                        $('.js-promo-send-btn').addClass('d-none');
                        helper.minimumOrder(data);
                    }
                    $('.coupon-code-field').val('');
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        } else {
            var $parent = $('.promo-code-field');
            var $label = $parent.find('label');
            var $errorText = $label.data('error');
            var $labelText = $label.data('label');

            $label.text($labelText).removeClass('error invalid');

            $.ajax({
                url: $form.attr('action'),
                type: 'GET',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    $.spinner().stop();

                    if (data.error) {
                        $label.text($errorText).addClass('error invalid');
                    } else {
                        $('#promo-box-ui').addClass('d-none');
                        helper.updateCartTotals(data);
                        helper.updateShippingSippingStatus(data);
                        helper.updateApproachingDiscounts(data);
                        helper.validateBasket(data);
                        braze.sendCouponApplyEvent(data.totals.discounts[0].couponCode, data.totals.value);
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
        
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                $('.new-grandTotal-box .right-payment .grand-total-amount').removeClass('green');
                // $('.promoApplied').removeClass('promoApplied');

                $('#promo-box-ui').removeClass('d-none');
                helper.updateCartTotals(data);
                helper.updateShippingSippingStatus(data);
                helper.updateApproachingDiscounts(data);
                helper.validateBasket(data);
                // $('#useIpoints').prop('checked', false);

                if (!isMobile) {
                    $('.js-promo-box:not(.shippingPage)').addClass('show');
                    if ($('.user-payment-instruments').hasClass('checkout-hidden')) {
                        $(document).trigger('desktop:newCardValidation');
                    } else {
                        $(document).trigger('desktop:saveCardValidation');
                    }

                } else {
                    $('.add_promo_code').text(resources.addPromo);
                    helper.minimumOrder(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                helper.updateCartTotals(data);
                helper.updateShippingSippingStatus(data);
                helper.updateApproachingDiscounts(data);
                helper.validateBasket(data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $(document).on('click', '.js-closepromopop', function (e) {
        e.preventDefault();
        $('.js-promo-box').hide();
        $('.add_promo_code').removeClass('unvisible');
    });

    $('.coupon-code-field').on('keyup', function () {
        var $this = $(this);
        if ($this.val().length > 0) {
            $('.js-closepromopop').addClass('d-none');
            // $('.js-promo-send-btn').removeClass('d-none');
        } else {
            $('.js-closepromopop').removeClass('d-none');
            // $('.js-promo-send-btn').addClass('d-none');
        }
    });

    // $('body').on('click', '.promo-code-btn', function () {
    //     var $this = $(this);
    //     var $form = $this.closest('.promo-code-form');
    //     console.log($form);
    //     $form.submit();
    // });

    // $('.coupon-code-field').on('keyup', function () {
    //     var $val = $(this).val();
    //     $('#js-stickey-header').val($val);
    // });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');
        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity
        };
        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    helper.updateCartTotals(data.cartModel);
                    helper.updateShippingSippingStatus(data.cartModel);
                    helper.updateApproachingDiscounts(data.cartModel);
                    helper.updateAvailability(data.cartModel, uuid);
                    helper.updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    helper.validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('click', '.js-cart-options', function (e) {
        showOptions(e);
    });

    $('body').on('click', '.js-proceed-shipping', function (e) {
        e.preventDefault();
        $('.js-hide-summary-section').trigger('click');
        $('.cartNewContainer, .cart-section-item, .bundleOfferSections, .discountBanner, .mobileSpecialPaymentError, .suggestionBox').fadeOut(100, function () {
            // eslint-disable-next-line no-undef
            $('.mobilePaymentError').removeClass('errorFound');
            $('.content-wrapper header h1').text(resources.shippingPageHeading2);
            $('.shipping-page-details').fadeIn();
            // $('.remove-coupon').hide();
            $('.bottom-floater.cart-shipping').show();
            $('.js-promo-box').addClass('shippingPage').removeClass('show').hide();
            $('.other-discountSection').hide();
            $('.content-wrapper').addClass('small-summary');
            $('.cart-page').addClass('smallSummary');
            $('.mobileHideInShipping').hide();
            $('.cart-level-button').hide();
            $('.cartOverlay').hide();
            $('.bottom-header .add_promo_code').addClass('unvisible');
            $('.walllet-box').hide();
            $(document).trigger('shipping:enabled');
        });
    });

    $('body').on('click', '.js-back-from-shipping', function (e) {
        e.preventDefault();
        history.back();
    });

    /**
     * function used to handle the display of selected payment method for mobile devices
     */
    $(document).on('click', '.js-select-payment-box-mobile', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $id = $this.data('id');
        $this.addClass('selected').siblings().removeClass('selected');
        $('.card-payment-area').removeClass('show');
        $('.js-cart-page-buttons').addClass('d-none');
        $('.js-select-payment-box-mobile-by-card').removeClass('selected');
        if ($id === 'paypal') {
            $('.bottom-floater .mobile-button.applePay').removeClass('show');
            $('.bottom-floater .mobile-button.paypal').addClass('show');
            $('.mobile-button.new-card-button').removeClass('show');
            $('.bottom-floater .mobile-button.careempay').addClass('hide');
            $('.mobile-button.existing-card-button').removeClass('show');
        } else if ($id === 'careempay') {
            const thirdPartyComponent = document.querySelector('.careempay .hydrated');
            if (thirdPartyComponent) {
                const shadowRoot = thirdPartyComponent.shadowRoot;
                // Modify styles of an element within the shadow DOM
                const element = shadowRoot.querySelector('.hint');
                const crmBtn = shadowRoot.querySelector('.cpay-checkout-button');
                element.style.display = 'none';
                crmBtn.style.minWidth = 'auto';
                crmBtn.style.lineHeight = '16px';
                crmBtn.style.fontSize = '14px';
            }
            $('.bottom-floater .mobile-button.applePay').removeClass('show');
            $('.bottom-floater .mobile-button.paypal').removeClass('show');
            $('.bottom-floater .mobile-button.careempay').addClass('show');
            $('.bottom-floater .mobile-button.careempay').removeClass('hide');
            $('.mobile-button.new-card-button').removeClass('show');
            $('.mobile-button.existing-card-button').removeClass('show');
            $('.mobile-button.existing-card-button').removeClass('show');
        } else if ($id === 'dw_apple_pay') {
            $('.bottom-floater .mobile-button.careempay').addClass('hide');
            $('.bottom-floater .mobile-button.applePay').addClass('show');
            $('.bottom-floater .mobile-button.paypal').removeClass('show');
            $('.mobile-button.new-card-button').removeClass('show');
            $('.mobile-button.existing-card-button').removeClass('show');
        } else {
            $('.bottom-floater .mobile-button.careempay').addClass('hide');
            $('.bottom-floater .mobile-button.applePay').removeClass('show');
            $('.bottom-floater .mobile-button.paypal').removeClass('show');
            $('.mobile-button.new-card-button').removeClass('show');
        }
       
        // set the respective tab pane active
        var classToActivated = '.' + $id + '-content';
        $('.tab-pane').removeClass('active');
        $(classToActivated).addClass('active');
    });

    /**
     * function used to handle the display of selected payment forms for mobile devices
     */
    $(document).on('click', '.js-select-payment-box-mobile-by-card', function(e) {
        e.preventDefault();
        var $this = $(this);
        $this.addClass('selected');
        var $id = $this.data('id');
        // set the respective tab pane active
        var classToActivated = '.' + $id + '-content';
        $('.tab-pane').removeClass('active');
        $(classToActivated).addClass('active');
        $('.js-cart-page-buttons').addClass('d-none');
        $('.card-payment-area').addClass('show');
        $('.js-select-payment-box-mobile').removeClass('selected');

        $('.bottom-floater .mobile-button.applePay').removeClass('show');
        $('.bottom-floater .mobile-button.paypal').removeClass('show');
        $('.bottom-floater .mobile-button.careempay').removeClass('show');
            $('.bottom-floater .mobile-button.careempay').addClass('hide');
        if ($('.saved-payment-instrument').length > 0) {
            $('.bottom-floater .mobile-button.credit_card.existing-card-button').addClass('show');
        } else {
            $('.bottom-floater .mobile-button.credit_card.new-card-button').addClass('show');
        }
    });

    /**
     * function used to handle the display of selected payment method for desktop devices
     */
     $(document).on('click', '.js-select-payment-box-desktop', function(e) {
        e.preventDefault();
        var isSizeSelected = checkIfSizeIsSelected();
        if (!isSizeSelected) {
            alert('Please select the sizes');
            return false;
        }
        var $this = $(this);
        var $id = $this.data('id');
        $this.addClass('selected').siblings().removeClass('selected');
        $('.card-payment-area').removeClass('show');
        $('.js-select-payment-box-desktop-by-card').removeClass('selected');
        // logic to handle buttons for desktop
        $('.js-payment-section-buttons .js-continue-to-payment').addClass('d-none');
        if ($id === 'paypal') {
            $('.js-payment-section-buttons').removeClass('d-none');
            $('.js-payment-section-buttons .desktop-button.applePay').addClass('d-none');
            $('.js-payment-section-buttons .desktop-button.paypal').removeClass('d-none');
            $('.desktop-button.new-card-button').addClass('d-none');
            $('.js-payment-section-buttons .desktop-button.careempay').addClass('d-none');
            $('.desktop-button.existing-card-button').addClass('d-none');
        } else if ($id === 'careempay') {
            const thirdPartyComponent = document.querySelector('.careempay .hydrated');
            if (thirdPartyComponent) {
                const shadowRoot = thirdPartyComponent.shadowRoot;
                // Modify styles of an element within the shadow DOM
                const element = shadowRoot.querySelector('.hint');
                const crmBtn = shadowRoot.querySelector('.cpay-checkout-button');
                element.style.display = 'none';
                crmBtn.style.minWidth = 'auto';
            }
            $('.js-payment-section-buttons').removeClass('d-none');
            $('.js-payment-section-buttons .desktop-button.applePay').addClass('d-none');
            $('.js-payment-section-buttons .desktop-button.careempay').removeClass('d-none').removeAttr('is-disabled');
            $('.js-payment-section-buttons .desktop-button.applePay').addClass('d-none');
            $('.js-payment-section-buttons .desktop-button.paypal').addClass('d-none');
            $('.desktop-button.new-card-button').addClass('d-none');
            $('.desktop-button.existing-card-button').addClass('d-none');
        } else if ($id === 'dw_apple_pay') {
            $('.js-payment-section-buttons .desktop-button.careempay').addClass('d-none');
            $('.js-payment-section-buttons').removeClass('d-none');
            $('.js-payment-section-buttons .desktop-button.applePay').removeClass('d-none');
            $('.js-payment-section-buttons .desktop-button.paypal').addClass('d-none');
            $('.desktop-button.newCard-button').addClass('d-none');
            $('.desktop-button.existing-card-button').addClass('d-none');
        } else {
            $('.js-payment-section-buttons .desktop-button.careempay').addClass('d-none');
            $('.js-payment-section-buttons').removeClass('d-none');
            $('.js-payment-section-buttons .desktop-button.applePay').addClass('d-none');
            $('.js-payment-section-buttons .desktop-button.paypal').addClass('d-none');
            $('.desktop-button.newCard-button').addClass('d-none');
        }
       
        // set the respective tab pane active
        var classToActivated = '.' + $id + '-content';
        $('.tab-pane').removeClass('active');
        $('.tab-pane').addClass('d-none');
        $(classToActivated).addClass('active').removeClass('d-none');
        $(document).trigger('desktop:walletPaymentValidation');
    });

    /**
     * function used to handle the display of selected payment forms for desktop devices
     */
    $(document).on('click', '.js-select-payment-box-desktop-by-card', function(e) {
        e.preventDefault();
        var isSizeSelected = checkIfSizeIsSelected();
        if (!isSizeSelected) {
            alert('Please select the sizes');
            return false;
        }
        var $this = $(this);
        $this.addClass('selected');
        var $id = $this.data('id');
        // set the respective tab pane active
        var classToActivated = '.' + $id + '-content';
        $('.tab-pane').removeClass('active');
        $('.tab-pane').addClass('d-none');
        $(classToActivated).addClass('active').removeClass('d-none');
        $('.tab-pane.CREDIT_CARD-content').addClass('active').removeClass('d-none');
        $('.js-payment-section-buttons .js-continue-to-payment').addClass('d-none');
        $('.card-payment-area').addClass('show');
        $('.js-select-payment-box-desktop').removeClass('selected');
        $('.js-payment-section-buttons').removeClass('d-none');
        $('.js-payment-section-buttons .desktop-button').addClass('d-none');
        $('.js-payment-section-buttons .submit-payment.desktop-button').removeClass('d-none');
    });


    if (window.location.href.indexOf('psperror') > -1) {
        var uri = window.location.toString();
        var cleanUri = uri.substring(0, uri.indexOf('?'));
        setTimeout(function () {
            $('#toast-container-psperror').fadeOut();
            window.history.replaceState({}, document.title, cleanUri);
        }, 3000);
    } else if ($('#toast-container-psperror .toast-warning').length > 0) {
        setTimeout(function () {
            $('#toast-container-psperror .toast-warning').parent().fadeOut();
        }, 3000);
    }

    $('body').on('click', '.js-proceed-payment', function (e) {
        e.preventDefault();
        if (isMobile) {
            // check if guest checkout session then save address before moving to next step
            if ($('.onlyForNonRegistered').length > 0 && $('.standard-shipping-box.checked').length > 0) {
                saveAddressToBasket();
            } else {
                goToPaymentPage();
            }
        }
    });

    $('body').on('click', '.card-item:not(.js-add-new-card) .saved-card-box', openSavedCard);

    $('body').on('click', '.js-add-new-card', openNewcard);
    $('body').on('click', '.js-firstcard', openNewcard);

    $('body').on('click', '.closeNewPayment', closeNewCard);

    $('.payment-selected-card').on('click', function (e) {
        e.preventDefault();
        openPaymentOverlayer();
    });

    $('body').on('click', '.closeCartOverlay', closePaymentOverlayer);

    $('body').on('click', '.js-add-bundle-to-cart', function () {
        var bundlePids = [];
        var $this = $(this);
        var $parent = $('.bundle-container');
        $parent.find('.bundleLineItem-Box').each(function () {
            // try to add products individually
            var pid = $(this).attr('product-id');
            if (pid) {
                bundlePids.push(pid);
            }
        });

        if (bundlePids.length > 0) {
            $.spinner().start();
            var action = $('.bundleOfferSections').attr('data-action');
            $.ajax({
                url: action,
                type: 'post',
                data: { pids: JSON.stringify(bundlePids) },
                success: function (data) {
                    if (!data.error) {
                        $('body').trigger('cart:addTOCartGTM', $this);
                        $(this).addClass('added');
                        $(this).find('span').html(resources.addedItems);
                        var data = { event: $this.attr('data-gtag-add-event'), param: $this.attr('data-gtag-add-param') };
                        braze.handleAddRemoveEvent(data);
                        $.spinner().stop();
                        window.location.reload();
                    } else {
                        createErrorNotification(data.message);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('click', '.suggestion-container .add-toCart', function (e) {
        e.preventDefault();
        var suggestionPid = $(this).parents('.bundleLineItem-Box').attr('product-id');
        if (suggestionPid !== '') {
            var suggestionPids = [];
            suggestionPids.push(suggestionPid);
            var action = $(this).attr('data-action');
            var $this = $(this);
            $.spinner().start();
            $.ajax({
                url: action,
                type: 'post',
                data: { pids: JSON.stringify(suggestionPids) },
                success: function (data) {
                    if (!data.error) {
                        $.spinner().stop();
                        $('body').trigger('cart:addTOCartGTM', $this);
                        // trigger braze event
                        var data = { event: $this.attr('data-gtag-add-event'), param: $this.attr('data-gtag-add-param') };
                        braze.handleAddRemoveEvent(data);
                        window.location.reload();
                    } else {
                        createErrorNotification(data.message);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('click', '.js-view-summary-section', function (e) {
        $(this).addClass('hide');
        $('#payment-new-summary-section').slideDown();
    });

    $('body').on('click', '.js-hide-summary-section', function (e) {
        $('#payment-new-summary-section').slideUp();
        $('.js-view-summary-section').removeClass('hide');
    });

    var $jsons = $('#lootieAnimDonate').data('url');
    lottie.loadAnimation({
        container: document.getElementById('lootieAnimDonate'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: $jsons // the path to the animation json
    });

    var $jsonss = $('#lootieAnimSelf').data('url');
    lottie.loadAnimation({
        container: document.getElementById('lootieAnimSelf'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: $jsonss // the path to the animation json
    });

    var $jsonsss = $('#lootieAnimStandard').data('url');
    lottie.loadAnimation({
        container: document.getElementById('lootieAnimStandard'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: $jsonsss // the path to the animation json
    });

    $(document).on('approaching:applied', function () {
        if ($('.successfullyApplied')) {
            setTimeout(function () {
                $('.successfullyApplied').addClass('d-none');
            }, 5000);
        }
    });

    $(document).scroll(function () {
        if (isMobile && $('.cart-page').length > 0) {
            if ($(window).scrollTop() > 100) {
                $('.withProgress').addClass('scrollingHead');
            }
            else if ($(window).scrollTop() <= 150) {
                $('.withProgress').removeClass('scrollingHead');
            }
        }
    });

    $(document).on('click', '.js-back-history', function (e) {
        e.preventDefault();
        history.back();
    });

    // logic to handle weekly campaign feature using toggle
    $(document).on('change', '.js-cart-weekly-module input', function (e) {
        e.preventDefault();
        $.spinner().start();
        if($(this).is(":checked")) {
            var action = $(this).data('action-add');
            $.ajax({
                url: action,
                type: 'get',
                success: function(data) {
                    window.location.href = data.redirectUrl;
                    $.spinner().stop();
                }
            });
        } else {
            var action = $(this).data('action-remove');
            $.ajax({
                url: action,
                type: 'get',
                success: function(data) {
                    window.location.href = data.redirectUrl;
                    $.spinner().stop();
                }
            });
        }
    });

    // logic to handle weekly campaign feature using toggle
    $(document).on('click', '.weekly-winner-wrapper .weeklyBtn', function (e) {
        e.preventDefault();
        $.spinner().start();
        var action = $(this).attr('href');
        $.ajax({
            url: action,
            type: 'get',
            success: function(data) {
                window.location.href = data.redirectUrl;
                $.spinner().stop();
            }
        });
    });

    // function used to set the selected size for the lineitem
    $(document).on('click', '.sizes-module .size-list a', function(e) {
        e.preventDefault();
        $(this).addClass('active').siblings().removeClass('active');
        var $parentItem;
        var url;
        var uuid;
        var selectedSizes = [];
        if (isMobile) {
            $parentItem = $(this).parents('.cart-section-item');
            url = $parentItem.find('.size-selection-wrapper-js').data('add-sizes');
            uuid = $parentItem.find('.size-selection-wrapper-js').data('lineitem-uuid');
            var $productDom = $('.uuid-' + uuid);
            var selectedQty =  parseInt($productDom.find('.quantity-form input').val());
            $parentItem.find('.sizes-module .size-list a.active').each(function () {
                for (var d = 0; d < selectedQty; d++) {
                    selectedSizes.push($(this).data('value'));
                }
            });
        } else {
            $parentItem = $(this).parents('.size-selection-wrapper-js');
            uuid = $parentItem.data('lineitem-uuid');
            var $productDom = $('.uuid-' + uuid);
            var selectedQty =  parseInt($productDom.find('.quantity-form input').val());
            url = $parentItem.data('add-sizes');
            $parentItem.find('.sizes-module .size-list a.active').each(function () {
                for (var d = 0; d < selectedQty; d++) {
                    selectedSizes.push($(this).data('value'));
                }
            });
        }
        $.spinner().start();
        if (url && uuid) {
            $.ajax({
                url: url,
                type: 'POST',
                data: { uuid: uuid, sizes: JSON.stringify(selectedSizes)},
                success: function (response) {
                    if (!response.status) {
                        alert('can not update the size. please refresh the page and try again.')
                    }
                    // helper.updateSizeSelectionForCart(response);
                    helper.updateCartTotals(response);
                    if (!isMobile) {
                        helper.handleDesktopAllValidations(response);
                    } else {
                        helper.minimumOrder(response);
                    }
                    $.spinner().stop();
                }
            })
        }
    });
    

    mobileOtpFill();

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();

    // function click handle to open the payment page
    $(document).on('click', '.js-show-payment-page:not(.dummy-disabled)', function (e) {
        e.preventDefault();

        $('#cart-area-page').fadeOut(function() {
            $('.js-payment-page-section').addClass('show');
            $('.js-cart-page-buttons a').addClass('is-disabled').addClass('d-none');
            $('.js-payment-section-buttons').removeClass('d-none');
            $('.js-add-new-card').trigger('click');
            $('.js-cart-weekly-module').hide();
            // window.dispatchEvent( new Event('careempayready') );
        });

        $(document).trigger('initCheckout');
    });

    $(document).on('click', '.js-show-payment-page.dummy-disabled', function (e) {
        e.preventDefault();

        $('#new-falto-popup').addClass('show');
    });
    $(document).on('click', '.close-new-falto-popup', function (e) {
        e.preventDefault();
        $('#new-falto-popup').removeClass('show');
    });

    // function click handle to open the payment page
    $(document).on('click', '.js-back-from-payment', function (e) {
        e.preventDefault();
        window.location.reload();
    });
    // handle next payment button
    if (isMobile) {
        // function click handle to open the payment page
        $( document ).ready(function() {
            var isOptionVisible = $('.shipping-page-details .delivery-box:visible').length;
            if (isOptionVisible) {
                if (!($('.shipping-page-details .delivery-box').is(':checked'))) {
                    $('.newbuttonMinOrder .js-show-payment-page').addClass('dummy-disabled');
                }
            }
        });
    }
};
